import { useInitializExtendScriptWhenLoaded } from '@/hooks/useInitializExtendScriptWhenLoaded'
import Script from 'next/script'

const ExtendWarranty: React.FC = () => {
  useInitializExtendScriptWhenLoaded()
  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://sdk.helloextend.com/extend-sdk-client-bigcommerce-addon/v1/extend-sdk-client-bigcommerce-addon.min.js"
        onLoad={() => {
          document.dispatchEvent(new Event('ExtendBigcommerceAddonLoaded'))
        }}
      />
      <Script
        strategy="afterInteractive"
        src="https://sdk.helloextend.com/extend-sdk-client/v1/extend-sdk-client.min.js"
        onLoad={() => {
          document.dispatchEvent(new Event('ExtendClientLoaded'))
        }}
      />
    </>
  )
}

export default ExtendWarranty
