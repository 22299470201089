import { debug } from '@/services/Log'
import { useEffect, useState } from 'react'

export const useIsExtendScriptLoaded = () => {
  const [addonLoaded, setAddonLoaded] = useState(false)
  const [clientLoaded, setClientLoaded] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const clientLoadedSetter = () => {
      debug('ExtendClientLoaded')
      setClientLoaded(true)
    }
    if (window.Extend) {
      clientLoadedSetter()
      return () => {}
    }
    document.addEventListener('ExtendClientLoaded', clientLoadedSetter)
    return () => document.removeEventListener('ExtendClientLoaded', clientLoadedSetter)
  }, [])

  useEffect(() => {
    const addonLoadedSetter = () => {
      debug('ExtendBigcommerceAddonLoaded')
      setAddonLoaded(true)
    }
    if (window.ExtendBigCommerce) {
      addonLoadedSetter()
      return () => {}
    }
    document.addEventListener('ExtendBigcommerceAddonLoaded', addonLoadedSetter)
    return () => document.removeEventListener('ExtendBigcommerceAddonLoaded', addonLoadedSetter)
  }, [])

  useEffect(() => {
    if (!addonLoaded || !clientLoaded) {
      return
    }
    setLoaded(true)
  }, [addonLoaded, clientLoaded])

  return loaded
}

export default useIsExtendScriptLoaded
