import { useEffect, useState } from 'react'

import {
  EXTEND_ID,
  EXTEND_ENVIRONMENT,
} from '@/services/Configuration'
import { useIsExtendScriptLoaded } from '@/hooks/useIsExtendScriptLoaded'
import { log, debug } from '@/services/Log'

export const useInitializExtendScriptWhenLoaded = () => {
  const loaded = useIsExtendScriptLoaded()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!loaded || initialized) {
      debug('Extend script not loaded or already initialized', {
        loaded,
        initialized,
      })
      return
    }
    log('Initializing Extend script', {
      loaded,
      initialized,
      storeId: EXTEND_ID,
      environment: EXTEND_ENVIRONMENT,
    })
    window.Extend.config({ storeId: EXTEND_ID, environment: EXTEND_ENVIRONMENT })
    setInitialized(true)
  }, [loaded, initialized])
}

export default useInitializExtendScriptWhenLoaded
